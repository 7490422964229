import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiEndpoint } from '../config';

console.log("DiscordCallback PAGE");

const DiscordCallback = () => {
    console.log("DiscordCallback component is loaded");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching data...");

                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');
                const location = window.location.hostname === 'localhost' ? 'local' : 'web';

                console.log(`Code: ${code}, Location: ${location}`);

                // Get the ID token from local storage
                const idToken = localStorage.getItem('id_token');

                // Double-check that the code, location, and idToken variables are not null or undefined
                if (!code || !location || !idToken) {
                    console.error("Code, Location, or idToken is undefined");
                    return;
                }

                // Make the API request with the Bearer token
                const response = await axios.post(`${apiEndpoint}/discordCallback`, {
                    code,
                    location
                }, {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                });

                if (!response.data) {
                    console.error("Data is undefined");
                    return;
                }

                console.log("Data received:", response.data);

                localStorage.setItem('discordName', response.data.discordName);
                navigate('/');
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [navigate]);

    return (
        <div>
            <p>Processing Discord callback...</p>
        </div>
    );
};

export default DiscordCallback;
