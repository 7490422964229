import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Member from './pages/Member';
import Chants from './pages/Chants';
import CreateMember from './pages/CreateMember';
import Links from './pages/Links';
import Logo from './assets/Crest.png';
import './App.css';
import AssignMemberships from './pages/AssignMemberships';
import CheckIn from './pages/CheckIn';
import { trySilentLogin } from './auth/AuthUtils';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DiscordJoinPage from './pages/DiscordJoinPage';
import DiscordCallback from './pages/DiscordCallback';
import MemberEdit from './pages/MemberEdit';

function App() {
    const [userEmail, setUserEmail] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false); // New state to track admin status

    function decodeIdTokenAndSetStates() {
        const idToken = localStorage.getItem('id_token');
        if (idToken) {
            const payload = JSON.parse(atob(idToken.split('.')[1]));
            const email = payload.email;
            const groups = payload['cognito:groups'] || [];

            setUserEmail(email);
            setIsAdmin(groups.includes("367a5703-bc5c-452c-a37c-597aa0659e1c")); // Check for admin group
            localStorage.setItem('email', email);
            localStorage.setItem('groups', JSON.stringify(groups));
        }
    }

    useEffect(() => {
        trySilentLogin(setUserEmail);
        decodeIdTokenAndSetStates();
    }, []);

    function logout() {
        // Clear local storage
        localStorage.removeItem('id_token');
        localStorage.removeItem('email');
        localStorage.removeItem('groups');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('userDetails');

        localStorage.clear()

        // Reset application state
        setUserEmail(null);

        // Redirect the user to the home page
        window.location.href = '/';
    }


    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <div className="header-top">
                        <img src={Logo} alt="Company Logo" className="App-logo" />
                        {userEmail && (
                            <button className="logout-button" onClick={logout}>
                                Logout
                            </button>
                        )}
                    </div>
                    <nav>
                        <Link className="nav-link" to="/">Home</Link>
                        <Link className="nav-link" to="/chants">Chants</Link>
                        <a className="nav-link" href="https://www.riptides.club" target="_blank" rel="noopener noreferrer">Shop</a>
                        {userEmail && (
                            <>
                                <Link className="nav-link" to="/member">Member</Link>
                            </>
                        )}
                        {isAdmin && (
                            <a className="nav-link" href="https://admin.riptides.club" target="_blank" rel="noopener noreferrer">Admin</a>
                        )}
                    </nav>
                </header>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/member" element={userEmail ? <Member userEmail={userEmail} /> : null} />
                    <Route path="/createMember" element={<CreateMember />} />
                    <Route path="/assignMemberships" element={<AssignMemberships />} />
                    <Route path="/checkin" element={<CheckIn />} />
                    <Route path="/discordjoin" element={<DiscordJoinPage />} />
                    <Route path="/join-discord/:guid" element={<DiscordJoinPage />} />
                    <Route path="/api/discord/callback" element={<DiscordCallback />} />
                    <Route path="/memberEdit" element={<MemberEdit />} />
                    <Route path="/chants" element={<Chants />} />
                    <Route path="/links" element={<Links />} />
                </Routes>
                <footer className="App-footer">
                    <a href="https://www.instagram.com/riptides.club" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={icon({ name: 'instagram', style: 'brands' })} />
                    </a>
                    <a href="https://twitter.com/riptides_club" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={icon({ name: 'twitter', style: 'brands' })} />
                    </a>
                </footer>
            </div>
        </BrowserRouter>
    );

}

export default App;
