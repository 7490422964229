import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiEndpoint } from '../config';
import QRCode from 'qrcode.react';
import Logo from '../assets/Crest.png';
import './Member.css';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';
import FoundingMemberLogo from '../assets/Founding Member-01.png';
import { Link } from 'react-router-dom';



const Member = ({ userEmail }) => {
    const [userDetail, setUserDetail] = useState(null);
    const [pendingMemberships, setPendingMemberships] = useState([]);
    const [memberStatus, setMemberStatus] = useState([]);
    const [memberNumber , setmemberNumber ] = useState([]);
    const [activeMemberships , setActiveMemberships ] = useState([]);
    const [discord , setDiscord ] = useState([]);
    const [fetchAttempts, setFetchAttempts] = useState(0);
    const navigate = useNavigate();
    const leadershipColor = userDetail?.leadership ? "leadership-background" : "";
    const [email, setUserEmail] = useState(userEmail);
    const [isAdmin, setIsAdmin] = useState(false); // New state to track admin status



    useEffect(() => {
        const fetchData = async (idToken) => {
            try {
                const response = await axios.get(`${apiEndpoint}/getUserDetail`, {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                });

                // Check if userDetail is null or undefined
                if (!response.data.userDetail) {
                    navigate('/CreateMember');
                    return;
                }

                setDiscord(response.data.discord || []);

                setUserDetail(response.data.userDetail);
                setPendingMemberships(response.data.pendingMemberships || []);
                setMemberStatus(response.data.memberStatus || []);
                setmemberNumber(response.data.memberNumber || []);
                setActiveMemberships(response.data.activeMemberships || []);

                console.log(discord)
                localStorage.setItem('pendingMemberships', JSON.stringify(response.data.pendingMemberships || []));
                localStorage.setItem('userDetails', JSON.stringify(response.data.userDetail || []));
                localStorage.setItem('discord', JSON.stringify(response.data.discord || []));
            } catch (error) {
                console.error('Error fetching user details:', error);
                if (fetchAttempts < 3) {
                    setFetchAttempts(fetchAttempts + 1);
                } else {
                    navigate('/');
                }
            }
        };

        (async () => {
            let idToken = localStorage.getItem('id_token');
            if (!idToken) {
                await trySilentLogin();
                idToken = localStorage.getItem('id_token');
            }
            if (idToken && fetchAttempts < 4) {
                fetchData(idToken);
            }
        })();
    }, [fetchAttempts, navigate]);

    const getEarliestYear = () => {
        console.log("Inside getEarliestYear function");
        console.log("userDetail:", userDetail);
        console.log("activeMemberships:", activeMemberships);

        if (userDetail && activeMemberships && activeMemberships.length > 0) {
            const years = activeMemberships.map(m => {
                console.log("Mapping year:", m.year);
                return parseInt(m.year);
            });
            console.log("Years array:", years);

            const minYear = Math.min(...years);
            console.log("Minimum year:", minYear);

            return minYear.toString();
        }

        return "Non-Member";
    };

    const isFoundingMember = () => {
        const earliestYear = getEarliestYear();
        console.log("Earliest Year:", earliestYear);
        const foundingStatus = earliestYear === '2023' || earliestYear === '2024';
        console.log("Is founding member:", foundingStatus);
        return foundingStatus;
    };


    const handleAssignMembershipsClick = () => {
        navigate('/assignMemberships', { state: { pendingMemberships } });
    };

    const handleEditButtonClickClick = () => {
        navigate('/MemberEdit');
    };

    const handleDiscordButtonClick = () => {
        navigate(`/join-discord/${userDetail.guid}`);
    };
    const handlePurchaseMembershipClick = () => {
        window.location.href = 'https://www.riptides.club';
    };

    console.log("userDetail:", userDetail);
    console.log("userDetail.discord:", discord);

    function decodeIdTokenAndSetStates() {
        const idToken = localStorage.getItem('id_token');
        if (idToken) {
            const payload = JSON.parse(atob(idToken.split('.')[1]));
            const email = payload.email;
            const groups = payload['cognito:groups'] || [];

            setUserEmail(email);
            setIsAdmin(groups.includes("367a5703-bc5c-452c-a37c-597aa0659e1c")); // Check for admin group
            localStorage.setItem('email', email);
            localStorage.setItem('groups', JSON.stringify(groups));
        }
    }

    useEffect(() => {
        trySilentLogin(setUserEmail);
        decodeIdTokenAndSetStates();
    }, []);

    return (
        <div>
            {pendingMemberships.length > 0 && (
                <div className="pending-membership-bar">
                    You have pending memberships to apply. You need to assign them to others.
                    <button className="assign-membership-button" onClick={handleAssignMembershipsClick}>
                        Assign Memberships
                    </button>
                </div>
            )}

            {userDetail ? (
                memberStatus === 'active' ? (
                    <div className="active-status-bar">Your membership is active</div>
                ) : (
                    <div className="pending-membership-bar">
                        Your membership is not active.
                        <button className="purchase-membership-button" onClick={handlePurchaseMembershipClick}>
                            Purchase Membership
                        </button>
                    </div>
                )
            ) : null}

            <div className="content-container">
                <div className={`member-card ${leadershipColor}`}>
                    <div className="header-flex">
                        <div className="top-left">
                            <img className="logo-image" src={Logo} alt="Logo" />
                        </div>
                        <div className="top-middle">
                            <p>{(memberNumber && memberNumber !== 'None') ? `No. ${memberNumber}` : 'Non-Member'}</p>
                        </div>
                        <div className="top-right">
                            <QRCode value={userDetail ? userDetail.guid : 'loading'} />
                        </div>
                    </div>

                    <div className="info-container">
                        <div className="bottom-left">
                            {userDetail ? (
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>{userDetail?.leadership ? `Title:` : `Email:`}</td>
                                        <td>{userDetail?.leadership ? userDetail.leadershipTitle : userEmail}</td>
                                    </tr>
                                    <tr>
                                        <td>First Name:</td>
                                        <td>{userDetail.firstName}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Name:</td>
                                        <td>{userDetail.lastName}</td>
                                    </tr>
                                    {getEarliestYear() !== "None" && getEarliestYear() !== "Non-Member" && (
                                        <tr>
                                            <td>Year Joined:</td>
                                            <td>{getEarliestYear()}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            ) : (
                                'Loading...'
                            )}
                        </div>

                        {isFoundingMember() && (
                            <div className="bottom-right">
                                <img className="founding-member-logo" src={FoundingMemberLogo} alt="Founding Member" />
                            </div>
                        )}
                    </div>
                </div>
                {Array.isArray(discord) && discord.length === 0 && (
                    <div>
                        <button className="editButton" onClick={handleDiscordButtonClick}>
                            Join Discord
                        </button>
                        {/* Add a console.log to check if this block is entered */}
                        {console.log("Join Discord button should be rendered.")}
                    </div>
                )}
                {userDetail && (
                    <div className="editButton">
                        <button className="editButton" onClick={handleEditButtonClickClick}>
                            Edit Information
                        </button>
                    </div>
                )}

                {userDetail && (
                    <>
                    <div className="table-container">
                        <table className="json-details-table">
                            <tbody>
                            <tr>
                                <td>Official Membership Number:</td>
                                <td>{(memberNumber && memberNumber !== 'None') ? `No. ${memberNumber}` : 'Non-Member'}</td>
                            </tr>
                            <tr>
                                <td>Membership Years:</td>
                                <td>{activeMemberships ? activeMemberships.map(m => parseInt(m.year)).join(', ') : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>First Name:</td>
                                <td>{userDetail.firstName || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Last Name:</td>
                                <td>{userDetail.lastName || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Zip Code:</td>
                                <td>{userDetail.zipCode || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Interested in Volunteering:</td>
                                <td>{userDetail.volunteer.toString() || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Interested in Leadership:</td>
                                <td>{userDetail.leadershipInterest.toString() || 'N/A'}</td>
                            </tr>
                            </tbody>
                        </table>
                        {/* Discord Join Button */}
                     </div>
                    </>


                )}

            </div>

        </div>

    );

};

export default Member;

