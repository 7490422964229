import React from 'react';
import './Links.css'; // Import your CSS file

function Links() {
    return (

        <div className="full-screen-background">
        <div className="button-container">
            <h1> Follow Us Below</h1>

            <a href="https://members.riptides.club" className="link-button">Become a Riptide</a>
            <a href="https://riptides.club" className="link-button" target="_blank" rel="noopener noreferrer">Merch & Membership</a>
            <a href="https://www.instagram.com/riptides.club" className="link-button" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://twitter.com/riptides_club" className="link-button" target="_blank" rel="noopener noreferrer">Twitter</a>
            {/* Add more links as needed */}
        </div>
        </div>
    );
}

export default Links;