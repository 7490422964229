import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../auth/authentication';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check for user authentication status
        const idToken = localStorage.getItem('id_token');

        if (idToken) {
            // If user is logged in, navigate to Member page
            navigate('/member');
        }
    }, [navigate]);

    return (
        <div className="home-background">
            <div className="home-container">
                <div className="home-content">
                    <h1 className="home-header">Welcome to Riptides Supporters Group</h1>
                    <div className="home-instructions">
                        <p>
                            For a seamless experience, please sign up using the same email address you've used to purchase a membership on our Shopify store.
                        </p>
                    </div>
                    <button className="home-button" onClick={authorize}>
                        Login / Sign Up
                    </button>
                </div>
            </div>
        </div>

    );
};

export default Home;
