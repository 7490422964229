import React from "react";
import "./Chants.css";

function App() {
    return (
        <div className="full-screen-background">
            <div className="container">
                <div className="song">
                    <h1 className="song-title">We Are SDFC!</h1>
                    <p className="lyrics">
                        We are! We are!<br />
                        Riptides SG!<br />
                        <br />
                        We are! We are!<br />
                        SDFC!<br />
                        <br />
                        <br />
                        Set to POD - Youth of The Nation.
                    </p>
                </div>
                <div className="divider"></div>
                <div className="song">
                    <h1 className="song-title">SD! FC!</h1>
                    <p className="lyrics">
                        SD!<br />
                        FC!<br />
                        <br />
                        SD!<br />
                        FC!<br />
                        <br />
                        SD!<br />
                        FC!<br />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default App;